// 上に戻るボタン
var fixed = $('.toTop');
if(($('body').height() + 450) <= $(window).height()){
  if(!fixed.hasClass('absolute')) fixed.addClass('absolute');
}
$(window).scroll(function () {
  contentHeight = $('body').height() + 0 - $(window).height();
  if(($('body').height() + 450) >= $(window).height()){
    if (contentHeight < $(this).scrollTop()) {
      if(!fixed.hasClass('absolute')) fixed.addClass('absolute');
    }
    else{
      if(fixed.hasClass('absolute')) fixed.removeClass('absolute');
    }
  }else{
    if(!fixed.hasClass('absolute')) fixed.addClass('absolute');
  }
});

const pageTopBtn = document.getElementById('topBtn');
pageTopBtn.addEventListener("click", function () {
  const me = arguments.callee;
  const nowY = window.pageYOffset;
  window.scrollTo(0, nowY - 100);
  if (nowY > 0) {
    window.setTimeout(me, 10);
  }
});
