var sp;
var breakpoint = 768;
var $memu;

$(document).ready(function (e) {
  $memu = $('.naviBox');

  //初期スマフォ判定
  if ($(window).width() < breakpoint) {
    sp = true;
  }
  else {
    sp = false;
  }

  $(window).on('load resize', function () {
    if ($(window).width() < breakpoint) {
      sp = true;
    }
    else {
      sp = false;

      if ($memu.is(':hidden')) {
        $memu.css('display', '');
      }
    }
  });

  // アンカーリンクのスムーズスクロール
  $('a').on('click', function () {
    var headerHight = 0;
    var speed = 1000;
    var href = $(this).attr("href");
    var target = href == "#" || href == "" ? 'html' : href;
    if ($(target)[0] != undefined) {
      var position = $(target).offset().top - headerHight;
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
      return false;
    }
  });

});
